import AclSettingsConfig from "./acl";
import ApplicationSettingsConfig from "./application_settings";
import CardConfig from "./cards";
import common from "./common.json";
import FinancialConfig from "./financial";
import menu from "./menu.json";
import RateAndPromoConfig from "./rate_and_promo";
import ReservationConfig from "./reservation";
import RevenuManagementConfig from "./revenu_management";
import RoomConfig from "./rooms";

const SettingsConfig = {
  common,
  menu,
  FinancialConfig,
  RateAndPromoConfig,
  CardConfig,
  ReservationConfig,
  RevenuManagementConfig,
  RoomConfig,
  ApplicationSettingsConfig,
  AclSettingsConfig,
};

export default SettingsConfig;
