import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { DepartureListModel } from "models/FrontDesk/DepartureModel";
import { FrontDeskFetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FrontDeskFetchingRequestParamsModels
  ): Promise<DepartureListModel> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_DEPARTURE_GET_ALL, { params })
      .then(({ data }) => data);
  };

export const checkout =
  (axios: AxiosInstance) =>
  async (data: {
    itemsIds: number[];
    departure?: string;
  }): Promise<DepartureListModel> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECKOUT), {
        room_reservation_ids: data?.itemsIds,
      })
      .then(({ data }) => data);
  };
