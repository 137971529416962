import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PoliciesGuard from "components/PoliciesGuard";
import RootContainer from "components/RootContainer";
import { SnackbarProviderStyled } from "components/styled/SnackbarProviderStyled";
import posthog from "posthog-js";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routing } from "routing";
import "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

if (process.env.NODE_ENV === "production") {
  window["console"]["log"] = function () { };
}

posthog.init("phc_3b3Yb4jhUYUGE9C4jvT1yTg5N2hrxHwRsSPFK3UmIN4", {
  api_host: "https://us.i.posthog.com",
  persistence: "localStorage",
  person_profiles: "always",
  loaded: (posthog) => {
    posthog.opt_out_capturing();
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProviderStyled maxSnack={10}>
          <PoliciesGuard>
            <RootContainer>
              <Routing />
            </RootContainer>
          </PoliciesGuard>
        </SnackbarProviderStyled>
      </QueryClientProvider>
    </LocalizationProvider>
  </ThemeProvider>
);

reportWebVitals();
