import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RoomPlanDataModel } from "models/Reservations/RoomPlanModel";
import {
  FormValuesModel,
  PlanReservationModel,
} from "pages/RoomPlanModule/pages/SchedulerPage/models";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params: {
    start_date: string;
    end_date: string;
    room_number?: string;
    search?: string;
    limit?: number;
    offset?: number;
  }): Promise<RoomPlanDataModel> => {
    return await axios
      .get(ReservationsEndpoint.ROOM_PLAN_DATA, { params })
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: Partial<FormValuesModel> & { share?: boolean }
  ): Promise<void> => {
    return await axios.post(
      sprintf(ReservationsEndpoint.ROOM_PLAN_UPDATE, data?.id!),
      data
    );
  };

export const getToAssign =
  (axios: AxiosInstance) =>
  async (params: {
    from: string;
    to: string;
    limit?: number;
    offset?: number;
  }): Promise<{ items: PlanReservationModel[]; total: number }> => {
    return await axios
      .get(ReservationsEndpoint.ROOM_PLAN_TO_ASSIGN, { params })
      .then(({ data }) => data);
  };
