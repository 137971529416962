import { AxiosInstance } from "axios";
import { ReservationService } from "services/Reservations";

export interface ReservationCommonServiceModel {
  discard: ReturnType<typeof ReservationService.discard>;
  checkRoomavAvilability: ReturnType<
    typeof ReservationService.checkRoomavAvilability
  >;
  getAll: ReturnType<typeof ReservationService.getAll>;
  getGuest: ReturnType<typeof ReservationService.getGuest>;
  updateGuest: ReturnType<typeof ReservationService.updateGuest>;
  searchCardGroup: ReturnType<typeof ReservationService.searchCardGroup>;
  searchToBillingRouteEntity: ReturnType<
    typeof ReservationService.searchToBillingRouteEntity
  >;
  confirm: ReturnType<typeof ReservationService.confirm>;
}

export const initReservationCommonService = (
  axios: AxiosInstance
): ReservationCommonServiceModel => ({
  discard: ReservationService.discard(axios),
  getAll: ReservationService.getAll(axios),
  getGuest: ReservationService.getGuest(axios),
  updateGuest: ReservationService.updateGuest(axios),
  searchCardGroup: ReservationService.searchCardGroup(axios),
  searchToBillingRouteEntity:
    ReservationService.searchToBillingRouteEntity(axios),
  confirm: ReservationService.confirm(axios),
  checkRoomavAvilability: ReservationService.checkRoomavAvilability(axios),
});
