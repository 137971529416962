import { Components, Theme } from "@mui/material";

export const MuiInputBase: Components<
  Omit<Theme, "components">
>["MuiInputBase"] = {
  defaultProps: {
    inputProps: {
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
      },
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: "3em",
      // height: "3em",
      padding: 0,
      "&.MuiFilledInput-root": {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.inter16.fontSize,
        height: "3.5em",
      },

      "&.MuiOutlinedInput-root": {
        padding: 0,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.inter16.fontSize,
        input: {
          //  backgroundColor: "red",
          padding: 0,
          height: "3rem",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
      "&.MuiInput-root": {
        fontSize: theme.typography.inter16.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        input: {
          padding: 0,
          height: "2.75rem",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
        {
          WebkitAppearance: "none",
          margin: 0,
        },
    }),
    multiline: {
      boxSizing: "content-box",
      textarea: {
        padding: "0.5rem 1rem 0.5rem 1rem",
      },
    },
    sizeSmall: ({ theme }) => ({
      "&.MuiOutlinedInput-root": {
        height: "40px",
      },
      "&.MuiInput-root": {
        height: "40px",
        fontWeight: "blod",
        fontSize: theme.typography.inter14.fontSize,
        input: {
          height: "30px",
          //   border: "solid 1px red",
          //  paddingTop: theme.spacing(0.5),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
      },
      "&.MuiFilledInput-root": {
        height: "48px",
        fontWeight: theme.typography.fontWeightSemibold,
        fontSize: theme.typography.inter14.fontSize,
        input: {
          padding: "21px 18px 0px 10px",
          height: 21,
        },
      },
    }),
    adornedStart: ({ theme }) => ({
      "&.MuiInput-root": {
        fontSize: theme.typography.inter16.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        input: {
          paddingLeft: 0,
          paddingRight: theme.spacing(2),
        },
      },
    }),
  },
};
